.topnav {
    overflow: hidden;
    margin-top: 15px;
}

.topnav a {
    float: left;
    display: block;
    color: #f2f2f2;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    font-size: 17px;
}

/* Change the color of links on hover */
.topnav a:hover {
    background-color: #ddd;
    // color: black;
}

/* Add an active class to highlight the current page */
.topnav a.active {
    // background-color: #04aa6d;
    color: white;
}

/* Hide the link that should open and close the topnav on small screens */
.topnav .icon {
    display: none;
}

.navContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.profileContainer {
    display: flex;
    align-items: center;
    gap: 20px;
}

.profile {
    display: flex;
    align-items: center;
    gap: 5px;
}

.profilePicture {
    width: 35px;
    height: 35px;
}

.perxButton {
    border-radius: 8px;
    background: #0e237d;
    display: flex;
    align-items: center;
    border: none;
    cursor: pointer;
    width: 150px;
    justify-content: center;
    height: 40px;
    color: #fff;
    gap: 7px;
}

.profilePicture > img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
}

.navText1 {
    color: var(--neutral-900, #0f172a);
    font-size: 24px;
    font-family: "Outfit";
    font-style: normal;
    font-weight: 500;
    line-height: 140.625%;
}

.navText2 {
    color: #747272;
    font-size: 16px;
    font-family: Outfit;
    font-style: normal;
    font-weight: 400;
    line-height: 140.625%;
}


.profileName {
    color: #747272;
    font-size: 16px;
    font-family: "Outfit";
    font-style: normal;
    font-weight: 400;
    line-height: 140.625%;
}

.Hamburger {
    display: none;
}

/* When the screen is less than 600 pixels wide, hide all links, except for the first one ("Home"). Show the link that contains should open and close the topnav (.icon) */
@media screen and (max-width: 888px) {
    .topnav a:not(:first-child) {
        display: none;
    }
    .topnav a.icon {
        float: right;
        display: block;
    }

    .Hamburger {
        display: block;
    }
}

@media screen and (max-width: 658px) {
    .profileName {
        display: none;
    }

    .perxButton {
        display: none;
    }
}
