.topCard {
    background: #fff;
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
}



.barChartContainer {
    height: 100%;
    border-radius: 10px;
    border: 1px solid #e8e8e8;
    background: #fff;
    padding: 10px;
    margin-top: 30px;
}

.barChartTop {
    margin-bottom: 30px;
}

.reportTop > h4 {
    color: var(--neutral-900, #0f172a);
    font-family: "Outfit";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140.625%;
}

.errorMessageStyling {
    font-family: "Outfit";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140.625%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: firebrick;
}

.loaderContainer {
    display: flex;
    justify-content: center;
    height: 300px;
}

.nodataContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 300px;
}

// .recharts-surface {
//     width: 100%;
//     width: 1000px !important;
// }

.reportTop > p {
    color: #747272;
    font-family: "Outfit";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140.625%;
}

.barChartTopContainer {
    display: flex;
    justify-content: space-between;
}

tspan {
    font-size: 12px;
}


.pieChartContainer {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
}

.dropdownContainer {
    display: flex;
    align-items: center;
    gap: 10px;
}
.dateContainer {
    display: flex;
    align-items: center;
    margin-top: 20px;
    gap: 10px;
}

.innerDateContainer {
    display: flex;
    flex-direction: column;
}

.innerDateContainer > input {
    width: 150px;
    height: 40px;
}

.pieContainer {
    width: 48%;
    border-radius: 10px;
    border: 1px solid #e8e8e8;
    background: #fff;
    padding: 20px;
}

.pieContainerForfieture {
    width: 100%;
    border-radius: 10px;
    border: 1px solid #e8e8e8;
    background: #fff;
    padding: 20px;
}

.leftCardContainer {
    width: 65%;
    border-radius: 10px;
    border: 1px solid #e8e8e8;
    background: #fff;
    padding: 10px;
}

.rightCardContainer {
    width: 33%;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #e8e8e8;
    background: #fff;
}

.cardContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
    padding: 20px 0px;
}

.CASCard1,
.CASCard2,
.CASCard3,
.CASCard4,
.CASCard5,
.CASCard6 {
    width: 40%;
    min-width: 300px;
    height: 100px;
    border-radius: 5px;
    border-radius: 5px;
    padding: 20px;

    p {
        color: var(--White, #fff);
        font-family: "Outfit";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 140.625%;
        text-transform: capitalize;
    }

    h4 {
        color: #fff;
        font-family: "Outfit";
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 140.625%;
    }
}

.CASCard1 {
    background: #0d99ff;
}

.CASCard2 {
    background: #989898;
}

.CASCard3 {
    background: #003B65;
}

.CASCard4 {
    background: #002640;
}
.CASCard5 {
    background: #ffc400;
}

.CASCard6 {
    background: #7b61ff;
}

.usuageCountText {
    color: #000;
    font-family: "Outfit";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.usageCountInnerContainer {
    display: flex;
    justify-content: space-between;
}

.usuageCount1,
.usuageCount2,
.usuageCount3,
.usuageCount4,
.usuageCount5,
.usuageCount1Redemp,
.usuageCount2Redemp,
.usuageCount3Redemp,
.usuageCount4Redemp,
.usuageCount5Redemp {
    width: 20%;
    padding-left: 5px;
}

.usuageCount1Redemp {
    border-left: 3px solid #ff6b6a;
}
.usuageCount2Redemp {
    border-left: 3px solid #ffcd29;
}
.usuageCount3Redemp {
    border-left: 3px solid #7b61ff;
}
.usuageCount4Redemp {
    border-left: 3px solid #e1e7ff;
}
.usuageCount5Redemp {
    border-left: 3px solid #0d99ff;
}

.usuageCount1 {
    border-left: 3px solid #0d99ff;
}

.usuageCount2 {
    border-left: 3px solid #0e237d;
}

.usuageCount3 {
    border-left: 3px solid #989898;
}

.usuageCount4 {
    border-left: 3px solid #4fc143;
}

.usuageCount5 {
    border-left: 3px solid #27824d;
}
.usuageTitle {
    color: #000;
    font-family: "Outfit";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.usuageNumber {
    color: #000;
    font-family: "Outfit";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.incrementalReportContainer {
    display: flex;
    gap: 10px;
}

.incrementalBTN {
    height: 40px;
    color: #fff;
    font-family: "Outfit";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140.625%;
    text-transform: capitalize;
    background-color: #0E237D;
    border: 1px solid #ccc;
}

@media (max-width: 2228px) {
    .CASCard5,
    .CASCard6 {
        width: 100%;
    }
}

@media (max-width: 1269px) {
    .topCard {
        flex-direction: column;
        gap: 30px;
    }

    .leftCardContainer,
    .rightCardContainer {
        width: 100%;
    }

    .CASCard5,
    .CASCard6 {
        min-width: 300px;

        width: 40%;
    }
}

@media (max-width: 1035px) {
    .pieChartContainer {
        flex-direction: column;
        gap: 30px;
    }

    .pieContainer {
        width: 100%;
    }
}

@media (max-width: 923px) and (min-width: 889px) {
    .CASCard1,
    .CASCard2,
    .CASCard3,
    .CASCard4,
    .CASCard5,
    .CASCard6 {
        width: 100%;
    }
}

@media (max-width: 712px) {
    .incrementalReportContainer {
        flex-direction: column;
        gap: 10px;
        margin-bottom: 30px;
    }

    .incrementalBTN {
        width: 100%;
    }

    .incrementalBTN {
        height: 40px;
        width: 150px;
    }
}

@media (max-width: 673px) {
    .CASCard1,
    .CASCard2,
    .CASCard3,
    .CASCard4,
    .CASCard5,
    .CASCard6 {
        width: 100%;
    }

    .usageCountInnerContainer {
        flex-wrap: wrap;
        gap: 10px;
    }

    .usuageCount1,
    .usuageCount2,
    .usuageCount3,
    .usuageCount4,
    .usuageCount5,
    .usuageCount1Redemp,
    .usuageCount2Redemp,
    .usuageCount3Redemp,
    .usuageCount4Redemp,
    .usuageCount5Redemp {
        width: 120px;
    }
}

@media (max-width: 490px) {
    .barChartTop {
        text-align: center;
    }

    .dropdownContainer {
        justify-content: space-between;
    }

    .incrementalReportContainer {
        flex-direction: row;
        justify-content: space-between;
        gap: 10px;
    }
    .dateContainer,
    .barChartTopContainer {
        flex-direction: column;
        text-align: center;
        margin-bottom: 30px;
    }
}

@media (max-width: 370px) {
    .dropdownContainer {
        flex-direction: column-reverse;

        margin-bottom: 30px;
    }

    .incrementalReportContainer {
        flex-direction: column;
        gap: 10px;
        margin-bottom: 30px;
    }
}
