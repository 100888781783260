.dropdown {
    position: relative;
    display: inline-block;
    z-index: 10;
}

.dropdown__toggle {
    padding: 5px 10px;
    background-color: #fff;
    border: none;
    height: 40px;
    cursor: pointer;
    width: 150px;

    border: 1px solid #ccc;
}

.downloadDropdown__toggle {
    padding: 5px 10px;
    background-color: #fff;
    border: none;
    height: 40px;
    cursor: pointer;
    width: 150px;
    border: 1px solid #4fc143;
}

.dropdown__menu {
    position: absolute;
    overflow-y: scroll;
    top: 100%;
    left: 0;
    width: 100%;
    margin-top: 5px;
    padding: 0;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    list-style: none;
    height: 80px;
}

.downloadDropdown__menu {
    position: absolute;
    overflow-y: scroll;
    top: 100%;
    left: 0;
    width: 100%;
    margin-top: 5px;
    padding: 0;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    list-style: none;
}

.dropdown__item {
    padding: 8px 16px;
    cursor: pointer;
}
.downloadDropdown__item {
    padding: 8px 16px;
    cursor: pointer;
}

.downloadDropdown__item:hover {
    background-color: #f2f2f2;
}
.dropdown__item:hover {
    background-color: #f2f2f2;
}

.dropdownText {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.dropdownTextColor {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #4fc143;
}

@media (max-width: 525px) {
    .downloadDropdown__item {
        padding: 16px;
        cursor: pointer;
    }
}
